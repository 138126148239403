export default {
  badge: 'باج',
  close: 'داخستن',
  dataIterator: {
    noResultsText: 'هیچ تۆمارێکی هاوتا نەدۆزرایەوە',
    loadingText: 'بارکردنی ئایتمەکان...',
  },
  dataTable: {
    itemsPerPageText: 'ڕیزەکان بۆ هەر پەڕەیەک:',
    ariaLabel: {
      sortDescending: '.سەر بەرەو خوار ڕیزکراوە',
      sortAscending: '.سەر بەرەو ژوور ڕیزکراوە',
      sortNone: 'ڕیزنەکراوە.',
      activateNone: 'چالاککردن بۆ لابردنی ڕیزکردن.',
      activateDescending: 'چالاککردن بۆ ڕیزکردنی سەربەرەوخوار.',
      activateAscending: 'چالاککردن بۆ ڕیزکردنی سەر بەرەو ژوور.',
    },
    sortBy: 'ڕیزکردن بەپێی',
  },
  dataFooter: {
    itemsPerPageText: 'ئایتمەکان بۆ هەر پەڕەیەک:',
    itemsPerPageAll: 'هەمووی',
    nextPage: 'پەڕەی دواتر',
    prevPage: 'پەڕەی پێشوو',
    firstPage: 'پەڕەی یەکەم',
    lastPage: 'پەڕەی کۆتایی',
    pageText: '{0}-{1} لە {2}',
  },
  datePicker: {
    itemsSelected: '{0} دەسنیشانکراوە',
    nextMonthAriaLabel: 'مانگی داهاتوو',
    nextYearAriaLabel: 'ساڵی داهاتوو',
    prevMonthAriaLabel: 'مانگی پێشوو',
    prevYearAriaLabel: 'ساڵی پێشوو',
  },
  noDataText: 'هیچ داتایەک بەردەست نیە',
  carousel: {
    prev: 'بینراوی پێشوو',
    next: 'بینراوی داهاتوو',
    ariaLabel: {
      delimiter: 'سلایدی کارۆسێل {0} لە {1}',
    },
  },
  calendar: {
    moreEvents: '{0} زیاتر',
  },
  input: {
    clear: 'Clear {0}',
    prependAction: '{0} prepended action',
    appendAction: '{0} appended action',
  },
  fileInput: {
    counter: '{0} فایل',
    counterSize: '{0} فایل ({1} لە کۆی گشتی)',
  },
  timePicker: {
    am: 'پێش نیوەڕۆژ',
    pm: 'دوای نیوەڕۆژ',
  },
  pagination: {
    ariaLabel: {
      wrapper: 'ڕێنیشاندەری پەڕەگۆڕکێ',
      next: 'پەڕەی دواتر',
      previous: 'پەڕەی پێشوو',
      page: 'بڕۆ بۆ پەڕەی {0}',
      currentPage: 'پەڕەی ئێستا، پەڕە{0}',
    },
  },
  rating: {
    ariaLabel: {
      icon: 'Rating {0} of {1}',
    },
  },
  loading: 'Loading...',
}
