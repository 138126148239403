export default {
  badge: 'תג',
  close: 'סגור',
  dataIterator: {
    noResultsText: 'לא נמצאו תוצאות מתאימות',
    loadingText: 'טוען פריט...',
  },
  dataTable: {
    itemsPerPageText: 'שורות לעמוד:',
    ariaLabel: {
      sortDescending: 'ממוין לפי סדר עולה. לחץ להספקת המיון.',
      sortAscending: 'ממוין לפי סדר יורד. לחץ למיון לפי סדר עולה.',
      sortNone: 'לא ממוין. לחץ למיון לפי סדר עולה.',
      activateNone: 'הפעל להסרת המיון.',
      activateDescending: 'הפעל למיון יורד.',
      activateAscending: 'הפעל למיון עולה.',
    },
    sortBy: 'סדר לפי',
  },
  dataFooter: {
    itemsPerPageText: 'פריטים לדף:',
    itemsPerPageAll: 'הכל',
    nextPage: 'עמוד הבא',
    prevPage: 'עמוד הקודם',
    firstPage: 'עמוד ראשון',
    lastPage: 'עמוד אחרון',
    pageText: '{0}-{1} מתוך {2}',
  },
  datePicker: {
    itemsSelected: '{0} נבחרו',
    nextMonthAriaLabel: 'חודש הבא',
    nextYearAriaLabel: 'שנה הבאה',
    prevMonthAriaLabel: 'חודש שעבר',
    prevYearAriaLabel: 'שנה שעברה',
  },
  noDataText: 'אין נתונים זמינים',
  carousel: {
    prev: 'מצג קודם',
    next: 'מצג הבא',
    ariaLabel: {
      delimiter: 'Carousel slide {0} of {1}',
    },
  },
  calendar: {
    moreEvents: '{0} נוספים',
  },
  input: {
    clear: 'Clear {0}',
    prependAction: '{0} prepended action',
    appendAction: '{0} appended action',
  },
  fileInput: {
    counter: '{0} קבצים',
    counterSize: '{0} קבצים ({1} בסך הכל)',
  },
  timePicker: {
    am: 'AM',
    pm: 'PM',
  },
  pagination: {
    ariaLabel: {
      wrapper: 'ניווט עימוד',
      next: 'עמוד הבא',
      previous: 'עמוד הקודם',
      page: '{0} לך לעמוד',
      currentPage: '{0} עמוד נוכחי, עמוד',
    },
  },
  rating: {
    ariaLabel: {
      icon: 'Rating {0} of {1}',
    },
  },
  loading: 'Loading...',
}
