export default {
  badge: 'nişan',
  close: 'Bağla',
  dataIterator: {
    noResultsText: 'Uyğun məlumat tapılmadı',
    loadingText: 'Yüklənir... Zəhmət olmasa, gözləyin.',
  },
  dataTable: {
    itemsPerPageText: 'Səhifə başı sətir sayı:',
    ariaLabel: {
      sortDescending: 'Azalan sıra ilə düzülmüş.',
      sortAscending: 'Artan sıra ilə düzülmüş.',
      sortNone: 'Sıralanmamışdır. ',
      activateNone: 'Sıralamanı yığışdır.',
      activateDescending: 'Azalan sıra ilə düz.',
      activateAscending: 'Artan sıra ilə düz.',
    },
    sortBy: 'Sırala',
  },
  dataFooter: {
    itemsPerPageText: 'Səhifə başı sətir sayı:',
    itemsPerPageAll: 'Hamısı',
    nextPage: 'Növbəti səhifə',
    prevPage: 'Əvvəlki səhifə',
    firstPage: 'İlk səhifə',
    lastPage: 'Son səhifə',
    pageText: '{0} - {1} arası, Cəmi: {2} qeydiyyat',
  },
  datePicker: {
    itemsSelected: '{0} element seçildi',
    nextMonthAriaLabel: 'Növbəti ay',
    nextYearAriaLabel: 'Növbəti yıl',
    prevMonthAriaLabel: 'Keçən ay',
    prevYearAriaLabel: 'Keçən yıl',
  },
  noDataText: 'Bu görüntüdə məlumat yoxdur.',
  carousel: {
    prev: 'Əvvəlki görüntü',
    next: 'Növbəti görüntü',
    ariaLabel: {
      delimiter: 'Galereya səhifə {0} / {1}',
    },
  },
  calendar: {
    moreEvents: '{0} ədad daha',
  },
  input: {
    clear: 'Clear {0}',
    prependAction: '{0} prepended action',
    appendAction: '{0} appended action',
  },
  fileInput: {
    counter: '{0} fayl',
    counterSize: '{0} fayl (cəmi {1})',
  },
  timePicker: {
    am: 'AM',
    pm: 'PM',
  },
  pagination: {
    ariaLabel: {
      wrapper: 'Səhifələmə Naviqasiyası',
      next: 'Növbəti səhifə',
      previous: 'Əvəvlki səhifə',
      page: 'Səhifəyə get {0}',
      currentPage: 'Cari səhifə, Səhifə {0}',
    },
  },
  rating: {
    ariaLabel: {
      icon: 'Rating {0} of {1}',
    },
  },
  loading: 'Loading...',
}
